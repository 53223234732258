import React, { Suspense, lazy, useEffect, useState, useCallback } from "react";
import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { BlockUI } from "primereact/blockui";
import { useDispatch, useSelector } from "react-redux";
import SffLanguage from "./Components/Common/Languages/SffLanguage";
import LoginService from "./Components/Modules/Login/Utills/LoginService";
import ErrorBoundary from "./Components/Common/ErrorBoundary/ErrorBoundary";
import { RouteAuthorize } from "./Components/Common/RouteAuthorize/RouteAuthorize";
import SessionPopUp from "./JWT/SessionPopUp";
import { menuRole } from "./Components/Common/Helpers/FhConsts";
const Login = lazy(() => import("./Components/Modules/Login/Page/Login"));
const Navbar = lazy(() => import("./Components/Common/NavBar/Navbar"));
const Home = lazy(() => import("./Components/Modules/Home/Home"));
const ChildIlReview = lazy(() =>
  import("./Components/Modules/ChildILReview/Page/ChildIlReview")
);
const PhotPendingReview = lazy(() =>
  import("./Components/Modules/PhotoPendingReview/Page/PhotPendingReview")
);
const PendingPhotoMainPage = lazy(() =>
  import("./Components/Modules/PhotoPendingReview/Page/PendingPhotoMainPage")
);
const ChildVideoReview = lazy(() =>
  import("./Components/Modules/ChildIntroVideoReview/Page/ChildVideoReview")
);
const ChildIIPendingReview = lazy(() =>
  import("./Components/Modules/ChildILReview/Page/ChildIIPendingReview")
);
const ChildIIPendingReviewMainPage = lazy(() =>
  import(
    "./Components/Modules/ChildIntroVideoReview/Page/ChildVideoReviewMainPage"
  )
);
const ChildReplyReviewPendings = lazy(() =>
  import(
    "./Components/Modules/ChildReplyLetterReview/Page/ChildReplyReviewPendings"
  )
);
const ChildReplyLetterReview = lazy(() =>
  import(
    "./Components/Modules/ChildReplyLetterReview/Page/ChildReplyLetterReview"
  )
);
const ChristmasCardReviewPendings = lazy(() =>
  import(
    "./Components/Modules/ChristmasCardReview/Page/ChristmasCardReviewPendings"
  )
);
const ChristmasCardReview = lazy(() =>
  import("./Components/Modules/ChristmasCardReview/Page/ChristmasCardReview")
);
const ChildGreetingVideoReview = lazy(() =>
  import(
    "./Components/Modules/ChildGreetingVideoReview/Page/ChildGreetingVideoMainPage"
  )
);
const ChildGreetingVideoReviewDetails = lazy(() =>
  import(
    "./Components/Modules/ChildGreetingVideoReview/Page/ChildGreetingVideoReview"
  )
);
const ExceptionVideoMessage = lazy(() =>
  import(
    "./Components/Modules/ExceptionVideoMessageReview/Page/ExceptionVideoMessage"
  )
);
const ExceptionVideoMessageReview = lazy(() =>
  import(
    "./Components/Modules/ExceptionVideoMessageReview/Page/ExceptionVideoMessageReview"
  )
);
const TemplateConfiguration = lazy(() =>
  import("./Components/Modules/TemplateConfig/Page/LetterTypeLists")
);

const LetterCreationMode = lazy(() =>
  import("./Components/Modules/LetterCreationMode/Page/LetterCreationMode")
);

const ChildIntroLetterTemplate = lazy(() =>
  import("./Components/Modules/TemplateConfig/Page/ChildIntroLetterTemplate")
);

const ChildPreviewTemplate = lazy(() =>
  import("./Components/Modules/TemplateConfig/Page/PreviewTemplate")
);

const ChildILPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChildILReview/Page/ChildIlPreview")
);
const RlPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChildReplyLetterReview/Page/PreviewRlPdf")
);

const ChildCCPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChristmasCardReview/Page/ChristmasCardPreview")
);
const DownloadLetterTemp = lazy(() =>
  import("./Components/Modules/DownloadLetterTemp/Page/DownloadLetterTemp")
);
const childPhotoUpdate = lazy(() =>
  import("./Components/Modules/ChildPhotoUpdate/Page/ChildPhotoUpdate")
);
const childPhotoUpdateReview = lazy(() =>
  import("./Components/Modules/ChildPhotoUpdate/Page/ChildPhotoUpdateReview")
);
const sponsorLetter = lazy(() =>
  import("./Components/Modules/Sponsor Letter/Page/SponserLetter")
);
const sponsorLetterReview = lazy(() =>
  import("./Components/Modules/Sponsor Letter/Page/SponserLetterReview")
);
const sponsorLetterPreview = lazy(() =>
  import("./Components/Modules/Sponsor Letter/Page/PreviewSponserLetter")
);
const UploadSponsorMessage = lazy(() =>
  import("./Components/Modules/UploadSponsorMessage/Page/UploadSponsorMessage")
);
const UploadSponsorReview = lazy(() =>
  import("./Components/Modules/UploadSponsorMessage/Page/UploadSponsorReview")
);
const ChildRMPendingReview = lazy(() =>
  import(
    "./Components/Modules/ChildReplyMessage/Page/ChildReplyMessaagePending"
  )
);
const ChildReplyMessageReview = lazy(() =>
  import("./Components/Modules/ChildReplyMessage/Page/ChildReplyMessageReview")
);
const RmPreviewTemplate = lazy(() =>
  import("./Components/Modules/ChildReplyMessage/Page/PreviewRMPdf")
);
const CCApprovedRejectedPendings = lazy(() =>
  import(
    "./Components/Modules/ChildApprovedRejectedRecords/ChristmasCardApprovedRejectedReview/Page/CCApprovedRejectedPendings"
  )
);
const CCApprovedRejectedReview = lazy(() =>
  import(
    "./Components/Modules/ChildApprovedRejectedRecords/ChristmasCardApprovedRejectedReview/Page/CCApprovedRejectedReview"
  )
);
const CCApprovedRejectedPreview = lazy(() =>
  import(
    "./Components/Modules/ChildApprovedRejectedRecords/ChristmasCardApprovedRejectedReview/Page/CCApprovedRejectedPreview"
  )
);
const ChildIlTranslationPendings = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildILTranslation/Page/ChildIlTranslationPending"
  )
);
const ChildIlTranslationReview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildILTranslation/Page/ChildIlTranslationReview"
  )
);
const ChildILTranslationPreview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildILTranslation/Page/ChildIlTranslationPreview"
  )
);
const ChildIMTranslationPendings = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildIniatedMessageTranslation/Page/ChildIMTranslationPendings"
  )
);
const ChildIMTranslationPreview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildIniatedMessageTranslation/Page/PreviewIMTranslation"
  )
);
const ChildIMTranslationReview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildIniatedMessageTranslation/Page/ChildIMTranslationReview"
  )
);
const ChildRMTranslationPendings = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildReplyMessageTranslation/Page/ChildRMTranslationPending"
  )
);
const ChildRMTranslationReview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildReplyMessageTranslation/Page/ChildRMTranslationReview"
  )
);
const ChildRMTranslationPreview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChildReplyMessageTranslation/Page/PreviewTranslationRMPdf"
  )
);
const ChristmasCardTranslationPendings = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChristmasCardTranslation/Page/ChristmasCardTranslationPendings"
  )
);
const ChristmasCardTranslationReview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChristmasCardTranslation/Page/ChristmasCardTranslationReview"
  )
);
const ChristmasCardTranslationPreview = lazy(() =>
  import(
    "./Components/Modules/ChildEngagementTranslation/ChristmasCardTranslation/Page/ChristmasCardTranslationPreview"
  )
);
const DownloadApproveMessage = lazy(() =>
  import(
    "./Components/Modules/DownloadApprovedMessage/Page/DownloadApproveMessage"
  )
);
const menuRole1 = {
  qaReview: 80,
  admin: 1,
  fo: 77,
  sdo: 82,
  gscUser: 74,
};
// const menuRole = {
//   qaReview: "APQAReviewer",
//   admin: "SuperAdmin",
//   fo: "APFacilitator",
//   sdo: "GlobalQAApprover",
//   gscUser: "SDOChildSponsorContributor",
// };

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const loginReduxData = useSelector((state) => state.LoginReducer);
  const action = useDispatch();
  const [loader, setLoader] = useState(false);
  const [invalidUser, setinvalidUser] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const { t, changeLanguage } = SffLanguage();
  const [showMenu, setShowMenu] = useState(false);
  const [roleId, setRoleId] = useState([]);
  const showMenuNavbar = (value) => {
    setShowMenu(value);
  };
  const checkUser = async () => {
    if (location.pathname != "/login") {
      try {
        setLoader(true);
        let response = await LoginService.userDetails();
        if (!response.isAxiosError) {
          if (response.data.success) {
            let tempRoles = response.data.data[0].AssignedRoles.reduce(
              (acc, curr) => {
                acc.push(curr.Role.Name);
                return acc;
              },
              []
            );
            setRoleId(tempRoles);
            localStorage.setItem("you", response.data.data[0].UserId);
            action({
              type: "updateLoginDetails",
              payload: {
                name: "userRoles",
                value: tempRoles,
              },
            });
            action({
              type: "updateLoginDetails",
              payload: {
                name: "loggedUserDetails",
                value: response.data.data,
              },
            });

            if (
              response.data.data[0].AssignedRoles[0].Role.Name !==
              "GlobalQAApprover"
            )
              getCountryDetails();
          }
        } else {
          if (response.response?.status === 401) {
            setinvalidUser(true);
          } else if (response.code == "ERR_NETWORK") {
            setNetworkError(true);
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoader(false);
      }
    }
  };
  const getCountryDetails = async () => {
    try {
      if (!localStorage.getItem("userName")) {
        window.location = "/login";
      } else {
        setLoader(true);
        let response = await LoginService.countyListDropDown();
        if (!response.isAxiosError) {
          if (response.data.success) {
            action({
              type: "updateLoginDetails",
              payload: {
                name: "countriesDropDown",
                value: response.data.data,
              },
            });
            action({
              type: "updateLoginDetails",
              payload: {
                name: "selectedCounty",
                value: response.data.data[0],
              },
            });
          }
        }
      }
    } catch {
      console.log("error county");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    checkUser();
    changeLanguage(localStorage.getItem("code"));
  }, [localStorage.getItem("userName")]);
  const suspenseFallback = (
    <div className="three-dot-fallback">
      <ThreeDots height="80" width="80" color="#40BBBD" />
    </div>
  );

  function routeElement(Component, roleId, menuRole) {
    return (
      <Suspense fallback={suspenseFallback}>
        <RouteAuthorize userRole={roleId} menuRole={menuRole}>
          <Component />
        </RouteAuthorize>
      </Suspense>
    );
  }
  const getMenuRole = useCallback(
    (roleId) => {
      return roleId.includes("APQAReviewer") ? menuRole.qaReview : menuRole.sdo;
    },
    [roleId]
  );

  return (
    <BlockUI
      blocked={loader}
      fullScreen
      template={<ThreeDots height="80" width="80" color="#40BBBD" />}
    >
      <SessionPopUp invalidUser={invalidUser} networkError={networkError} />
      <div className="App">
        {location.pathname != "/login" && (
          <Navbar showMenuNavbar={showMenuNavbar} />
        )}
        <div
          className="main-container"
          style={
            location.pathname != "/login"
              ? showMenu
                ? { marginLeft: "60px" }
                : { marginLeft: "290px" }
              : {}
          }
        >
          <Suspense fallback={suspenseFallback}>
            {/* <Suspense fallback={"Loading"}> */}
            <ErrorBoundary>
              <Routes>
                <Route path="/login" element={<Login />} />
                {roleId.length !== 0 && (
                  <>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route
                      path="/ilreview"
                      element={<ChildIIPendingReview />}
                    />
                    <Route
                      path="/rmreview"
                      element={routeElement(
                        ChildRMPendingReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/rmreview/operation"
                      element={routeElement(
                        ChildReplyMessageReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/rmreview/preview"
                      element={routeElement(
                        RmPreviewTemplate,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/ilreview/operation"
                      element={<ChildIlReview />}
                    />
                    <Route
                      path="/photoreview/operation"
                      element={routeElement(
                        PhotPendingReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/photoreview"
                      element={routeElement(
                        PendingPhotoMainPage,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/childvideoreview"
                      element={routeElement(
                        ChildIIPendingReviewMainPage,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/childvideoreview/operation"
                      element={routeElement(
                        ChildVideoReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/replyletterreview"
                      element={routeElement(
                        ChildReplyReviewPendings,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/replyletterreview/operation"
                      element={routeElement(
                        ChildReplyLetterReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/greetingvideo"
                      element={routeElement(
                        ChildGreetingVideoReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/greetingvideo/operation"
                      element={routeElement(
                        ChildGreetingVideoReviewDetails,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/exceptionvideo"
                      element={routeElement(
                        ExceptionVideoMessage,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/exceptionvideo/operation"
                      element={routeElement(
                        ExceptionVideoMessageReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/christmascard"
                      element={routeElement(
                        ChristmasCardReviewPendings,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/christmascard/operation"
                      element={routeElement(
                        ChristmasCardReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/sponsorletter"
                      element={routeElement(
                        sponsorLetter,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/sponsorletter/review"
                      element={routeElement(
                        sponsorLetterReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/sponsorletter/preview"
                      element={routeElement(
                        sponsorLetterPreview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/settings/templateconfiguration"
                      element={routeElement(
                        TemplateConfiguration,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/lettercreationmode"
                      element={routeElement(
                        LetterCreationMode,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/settings/templateconfiguration/add"
                      element={routeElement(
                        ChildIntroLetterTemplate,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/settings/templateconfiguration/preview"
                      element={routeElement(
                        ChildPreviewTemplate,
                        roleId,
                        menuRole.admin
                      )}
                    />
                    <Route
                      path="/ilreview/preview"
                      element={routeElement(
                        ChildILPreviewTemplate,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/christmascardtranslation"
                      element={routeElement(
                        ChristmasCardTranslationPendings,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/christmascardtranslation/operation"
                      element={routeElement(
                        ChristmasCardTranslationReview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/christmascardtranslation/preview"
                      element={routeElement(
                        ChristmasCardTranslationPreview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/ilreviewtranslation"
                      element={routeElement(
                        ChildIlTranslationPendings,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/ilreviewtranslation/operation"
                      element={routeElement(
                        ChildIlTranslationReview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/ilreviewtranslation/preview"
                      element={routeElement(
                        ChildILTranslationPreview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/replylettertranslation"
                      element={routeElement(
                        ChildIMTranslationPendings,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/replylettertranslation/operation"
                      element={routeElement(
                        ChildIMTranslationReview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/replylettertranslation/preview"
                      element={routeElement(
                        ChildIMTranslationPreview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/rmreviewtranslation"
                      element={routeElement(
                        ChildRMTranslationPendings,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/rmreviewtranslation/operation"
                      element={routeElement(
                        ChildRMTranslationReview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/rmreviewtranslation/preview"
                      element={routeElement(
                        ChildRMTranslationPreview,
                        roleId,
                        menuRole.volunteer
                      )}
                    />
                    <Route
                      path="/christmascard/preview"
                      element={routeElement(
                        ChildCCPreviewTemplate,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/replyletterreview/preview"
                      element={routeElement(
                        RlPreviewTemplate,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/settings/downloadletter"
                      element={routeElement(
                        DownloadLetterTemp,
                        roleId,
                        menuRole.fo
                      )}
                    />
                    <Route
                      path="/settings/downloadapprovemessage"
                      element={routeElement(
                        DownloadApproveMessage,
                        roleId,
                        menuRole.dam
                      )}
                    />
                    <Route
                      path="/childphotoupdate"
                      element={routeElement(
                        childPhotoUpdate,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/childphotoupdate/review"
                      element={routeElement(
                        childPhotoUpdateReview,
                        roleId,
                        getMenuRole(roleId)
                      )}
                    />
                    <Route
                      path="/uploadsponsormessage"
                      element={routeElement(
                        UploadSponsorMessage,
                        roleId,
                        menuRole.gscUser
                      )}
                    />
                    <Route
                      path="/uploadsponsormessage/operation"
                      element={routeElement(
                        UploadSponsorReview,
                        roleId,
                        menuRole.gscUser
                      )}
                    />
                    <Route
                      path="/christmascardapprovedrejected"
                      element={routeElement(
                        CCApprovedRejectedPendings,
                        roleId,
                        menuRole.emiley
                      )}
                    />
                    <Route
                      path="/christmascardapprovedrejected/operation"
                      element={routeElement(
                        CCApprovedRejectedReview,
                        roleId,
                        menuRole.emiley
                      )}
                    />
                    <Route
                      path="/christmascardapprovedrejected/preview"
                      element={routeElement(
                        CCApprovedRejectedPreview,
                        roleId,
                        menuRole.emiley
                      )}
                    />
                  </>
                )}
              </Routes>
            </ErrorBoundary>
          </Suspense>
        </div>
      </div>
    </BlockUI>
  );
}

export default App;
