const initialStateVal = {
  tableData: [],
  keyWiseData: {},
  rlDropdownOptions: {
    community: [],
    country: [],
  },
  rlDropdownValues: {
    community: [],
    country: [],
  },
  rlDetails: {},
  rlLetters: {},
  rlTranslationData: [],
  historyDetails: [],
};
const ReplyLetterTranslationReducer = (state = initialStateVal, action) => {
  switch (action.type) {
    case "replyLetterTableDataTranslation":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "rlintTransalation":
      let currentValue = [...state["rlTranslationData"]];
      currentValue[action.payload.propIndex]["interMediateTranslation"] =
        action.payload.propValue;

      return {
        ...state,
        [action.payload.name]: currentValue,
      };
    case "replyLetterFilterTranslation":
      return {
        ...state,
        [action.payload.tabName]: {
          ...state[action.payload.tabName],
          [action.payload.propName]: action.payload.propValue,
        },
      };
    case "rlOnClearTranslation":
      return {
        ...state,
        rlTranslationData: [],
        rlDetails: {},
        rlLetters: {},
      };
    default:
      return state;
  }
};
export default ReplyLetterTranslationReducer;
