const initialStateVal = {
    tableData: [],
    keyWiseData: {},
    dldropdownOptions: {
      community: [],
    },
    language: {},
    dldropdownValues: {
      community: {},
      letterType: {},
    },
    templateSectionDetails: [],
  };
  const DownloadApprMsgReducer = (state = initialStateVal, action) => {
    switch (action.type) {
      case "DownloadLetterData":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      case "DownloadLetterFilter":
        return {
          ...state,
          [action.payload.tabName]: {
            ...state[action.payload.tabName],
            [action.payload.propName]: action.payload.propValue,
          },
        };
      case "templateDetails":
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
  
      default:
        return state;
    }
  };
  export default DownloadApprMsgReducer;
  