import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    en: {
      translations: require("./en/translations.json"),
    },
    es: {
      translations: require("./es/translations.json"),
    },
    km: {
      translations: require("./km/translations.json"),
    },
    am: {
      translations: require("./am/translations.json"),
    },
    ht: {
      translations: require("./ht/translations.json"),
    },
    id: {
      translations: require("./id/translations.json"),
    },
    ka: {
      translations: require("./ka/translations.json"),
    },
    om: {
      translations: require("./om/translations.json"),
    },
    pt: {
      translations: require("./pt/translations.json"),
    },
    rw: {
      translations: require("./rw/translations.json"),
    },
    sa: {
      translations: require("./sa/translations.json"),
    },
    bn: {
      translations: require("./bn/translations.json"),
    },
    rn: {
      translations: require("./rn/translations.json"),
    },
    tl: {
      translations: require("./tl/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = [
  "en",
  "es",
  "km",
  "am",
  "ht",
  "id",
  "ka",
  "om",
  "pt",
  "rw",
  "sa",
  "bn",
  "rn",
  "tl",
];

export default i18n;
