const menuRole = {
  qaReview: "APQAReviewer",
  admin: "SuperAdmin",
  fo: "APFacilitator",
  sdo: "GlobalQAApprover",
  gscUser: "SDOChildSponsorContributor",
  volunteer: "SDOTranslator",
  emiley: "GlobalQAViewer",
  dam: "SDODownloadUser",
};
const FhStatus = {
  1: "Pending For Review",
  2: "AP QA Reviewed",
  3: "AP QA Rejected",
  4: "Global QA Approved",
  5: "Global QA Rejected",
};
const generalUser = [
  { name: "WBA_GEN_GENE_LBL_00061_PendRevi", code: 1 },
  { name: "WBA_GEN_GENE_LBL_00063_Revi", code: 2 },
];
const translatorUser = [
  { name: "WBA_GEN_GENE_LBL_00181_PendTran", code: 1 },
  { name: "WBA_GEN_GENE_LBL_00182_Tran", code: 2 },
];
const superUser = [
  { name: "WBA_GEN_GENE_LBL_00183_ApAppr", code: 1 },
  { name: "WBA_GEN_GENE_LBL_00184_ApReje", code: 2 },
  { name: "WBA_GEN_GENE_LBL_00185_GlobAppr", code: 3 },
  { name: "WBA_GEN_GENE_LBL_00186_GlobReje", code: 4 },
];

export { menuRole, FhStatus, generalUser, translatorUser, superUser };
